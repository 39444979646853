import React from 'react';
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';

const images = [
        {
            "original": "/images/0-B-obyvak2.jpg",
            "thumbnail": "/images/0-B-obyvak2.jpg",
            "description": "Obývací pokoj"
        },
        {
            "original": "/images/1-B-obyvak3.jpg",
            "thumbnail": "/images/1-B-obyvak3.jpg",
            "description": "Obývací pokoj"
        },
        {
            "original": "/images/2-B-obyvak6.jpg",
            "thumbnail": "/images/2-B-obyvak6.jpg",
            "description": "Obývací pokoj"
        },
        {
            "original": "/images/3-C-kuchyne2.jpg",
            "thumbnail": "/images/3-C-kuchyne2.jpg",
            "description": "Kuchyně"
        },
        {
            "original": "/images/4-F-druha-loznice3.jpg",
            "thumbnail": "/images/4-F-druha-loznice3.jpg",
            "description": "Druhá ložnice"
        },
        {
            "original": "/images/5-E-hlavni-koupelna2.jpg",
            "thumbnail": "/images/5-E-hlavni-koupelna2.jpg",
            "description": "Hlavní koupelna"
        },
        {
            "original": "/images/6-B-obyvak2.jpg",
            "thumbnail": "/images/6-B-obyvak2.jpg",
            "description": "Obývací pokoj"
        },
        {
            "original": "/images/7-H-terasa.jpg",
            "thumbnail": "/images/7-H-terasa.jpg",
            "description": "Terasa"
        },
        {
            "original": "/images/8-K-slunecni-terasa1.jpg",
            "thumbnail": "/images/8-K-slunecni-terasa1.jpg",
            "description": "Terasa"
        },
        {
            "original": "/images/9-C-zapad-slunce.jpg",
            "thumbnail": "/images/9-C-zapad-slunce.jpg",
            "description": "Západ slunce"
        },
        {
            "original": "/images/B-obyvak1.jpg",
            "thumbnail": "/images/B-obyvak1.jpg",
            "description": "Obývací pokoj"
        },
        {
            "original": "/images/B-obyvak4.jpg",
            "thumbnail": "/images/B-obyvak4.jpg",
            "description": "Obývací pokoj"
        },
        {
            "original": "/images/B-obyvak5.jpg",
            "thumbnail": "/images/B-obyvak5.jpg",
            "description": "Obývací pokoj"
        },
        {
            "original": "/images/C-kuchyne1.jpg",
            "thumbnail": "/images/C-kuchyne1.jpg",
            "description": "Kuchyně"
        },
        {
            "original": "/images/D-hlavni-loznice1.jpg",
            "thumbnail": "/images/D-hlavni-loznice1.jpg",
            "description": "Hlavní ložnice"
        },
        {
            "original": "/images/D-hlavni-loznice2.jpg",
            "thumbnail": "/images/D-hlavni-loznice2.jpg",
            "description": "Hlavní ložnice"
        },
        {
            "original": "/images/D-hlavni-loznice3.jpg",
            "thumbnail": "/images/D-hlavni-loznice3.jpg",
            "description": "Hlavní ložnice"
        },
        {
            "original": "/images/E-hlavni-koupelna1.jpg",
            "thumbnail": "/images/E-hlavni-koupelna1.jpg",
            "description": "Hlavní koupelna"
        },
        {
            "original": "/images/F-druha-loznice1.jpg",
            "thumbnail": "/images/F-druha-loznice1.jpg",
            "description": "Druhá ložnice"
        },
        {
            "original": "/images/F-druha-loznice2.jpg",
            "thumbnail": "/images/F-druha-loznice2.jpg",
            "description": "Druhá ložnice"
        },
        {
            "original": "/images/G-druha-koupelna1.jpg",
            "thumbnail": "/images/G-druha-koupelna1.jpg",
            "description": "Druhá koupelna"
        },
        {
            "original": "/images/H-terasa2.jpg",
            "thumbnail": "/images/H-terasa2.jpg",
            "description": "Terasa"
        },
        {
            "original": "/images/I-chodba.jpg",
            "thumbnail": "/images/I-chodba.jpg",
            "description": "Chodba"
        },
        {
            "original": "/images/J-pracka.jpg",
            "thumbnail": "/images/J-pracka.jpg",
            "description": "Pračka"
        },
        {
            "original": "/images/K-slunecni-terasa2.jpg",
            "thumbnail": "/images/K-slunecni-terasa2.jpg",
            "description": "Terasa"
        },
        {
            "original": "/images/vyhled-z-terasy.jpg",
            "thumbnail": "/images/vyhled-z-terasy.jpg",
            "description": "Výhled z terasy"
        }
];

const Gallery = () => {
    return <ImageGallery  items={images}/>;
};

export default Gallery;
