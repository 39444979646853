import React from 'react';
import {Box, Divider, List, ListItem, ListItemIcon, ListItemText, Typography} from '@mui/material';
import EuroIcon from '@mui/icons-material/Euro';
import CleaningServicesIcon from '@mui/icons-material/CleaningServices';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import {Image, ImageWrapper} from "./Carousel";

const Prices: React.FC = () => {
    return (
        <Box>
            <Typography variant="h4" component="h2" gutterBottom fontWeight="bold">
                Ceník
            </Typography>
            <ImageWrapper>
                <Image src="/images/9-C-zapad-slunce.jpg" alt="Zapad slunce"/>
            </ImageWrapper>
            <Typography variant="body1" gutterBottom sx={{mt:4}}>
                Užijte si klidné a pohodlné ubytování v nově zařízeném apartmánu v Mijas Golf.Ceny za noc jsou následující:
            </Typography>
            <List>
                <ListItem alignItems="flex-start">
                    <ListItemIcon><CalendarTodayIcon/></ListItemIcon>
                    <ListItemText
                        primary="Červenec, srpen:"
                        secondary={<Typography variant="body2" color="textSecondary">120 € za aparmán</Typography>}
                    />
                </ListItem>
                <Divider variant="inset" component="li"/>
                <ListItem alignItems="flex-start">
                    <ListItemIcon><CalendarTodayIcon/></ListItemIcon>
                    <ListItemText
                        primary="Květen, červen, září, říjen:"
                        secondary={<Typography variant="body2" color="textSecondary">100 € za apartmán</Typography>}
                    />
                </ListItem>
                <Divider variant="inset" component="li"/>
                <ListItem alignItems="flex-start">
                    <ListItemIcon><CalendarTodayIcon/></ListItemIcon>
                    <ListItemText
                        primary="Zbytek roku:"
                        secondary={<Typography variant="body2" color="textSecondary">80 € za apartmán</Typography>}
                    />
                </ListItem>
                <Divider variant="inset" component="li"/>
                <ListItem alignItems="flex-start">
                    <ListItemIcon><CleaningServicesIcon/></ListItemIcon>
                    <ListItemText
                        primary="Závěrečný úklid:"
                        secondary={<Typography variant="body2" color="textSecondary">110 €</Typography>}
                    />
                </ListItem>
            </List>

            <Typography variant="body1" sx={{mt: 4, mb: 2}}>
                <EuroIcon sx={{verticalAlign: 'middle'}}/> Lze platit v korunách i eurech.
            </Typography>

        </Box>
    )
};

export default Prices;
